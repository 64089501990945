import { inject, Injectable } from '@angular/core';
import { SalesMigrationService } from '@ev-portals/cp/frontend/shared/util';

@Injectable({
  providedIn: 'root',
})
export class SalesLogicPermissionGuard {
  #salesMigrationService = inject(SalesMigrationService);

  canActivate(): boolean {
    return this.#salesMigrationService.isSalesLogicPermitted();
  }

  canActivateChild(): boolean {
    return this.#salesMigrationService.isSalesLogicPermitted();
  }
}
